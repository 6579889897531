import React from "react";
import { graphql } from "gatsby";
import { WpCategory, WpPage, WpPost } from "codegen";
import { Layout } from "components";
import { ContactFormBlock, PostList, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";

interface PostsPageTemplateProps {
  location: Location & {
    state: {
      filteredPosts: WpPost[];
    };
  };
  data: {
    page: WpPage;
    posts: {
      nodes: WpPost[];
    };
    categories: {
      nodes: WpCategory[];
    };
  };
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    postsPerPage: number;
    numberOfPages: number;
    currentPageNumber: number;
  };
}

const PostsPageTemplate = ({ pageContext, data, location }: PostsPageTemplateProps): JSX.Element => {
  const { page, posts, categories, wp } = data;
  const { acfLayout, seo, content } = page;

  const allPosts = posts.nodes.map((node) => node);
  const allCategories = categories.nodes.map((node) => node);

  const { numberOfPages, currentPageNumber } = pageContext;
  return (
    <Layout pageTitle={page.title} seo={page.seo}>
      <>
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
              numberOfPages,
              currentPageNumber,
            }))}
            location={seo?.breadcrumbs}
            cptItems={allPosts}
            cptCategories={allCategories}
            browserLocation={location}
          />
        ) : (
          <PostList
            cptItems={allPosts}
            cptCategories={allCategories}
            browserLocation={location}
            data={{
              currentPageNumber: currentPageNumber,
              numberOfPages: numberOfPages,
            }}
          />
        )}
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "pages" })(PostsPageTemplate);

export const PostsPageQuery = graphql`
  query PostsPage($id: String!, $offset: Int!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      ...SEOPageQuery
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...FlexibleBlockQuery
          ...PostListQuery
          ...FeaturedPostsQuery
        }
      }
    }
    categories: allWpCategory {
      nodes {
        id
        name
        slug
        uri
      }
    }
    posts: allWpPost(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
      nodes {
        ...PostFields
      }
    }
  }
`;
